import useAuth, { User } from 'hooks/useAuth'
import { createContext, useContext, useMemo } from 'react'

interface UserState {
  loading: boolean
  user: User | null
}

export const UserContext = createContext<UserState>({
  loading: true,
  user: null,
})

export function useUserContext() {
  const ctx = useContext(UserContext)
  if (!ctx) {
    throw new Error('useUserContext must be used within an UserProvider.')
  }
  return ctx
}

export function UserProvider({ children }: { children: any }) {
  const [loading, user] = useAuth()
  const userContext = useMemo(() => ({ loading, user }), [loading, user])

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  )
}
