import { initializeApp } from 'firebase/app'
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  Analytics,
  getAnalytics,
  logEvent as logAnalyticsEvent,
} from 'firebase/analytics'
import {
  connectDatabaseEmulator,
  Database,
  getDatabase,
} from 'firebase/database'
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from 'firebase/functions'
import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage,
} from 'firebase/storage'
import { useState } from 'react'

const firebaseConfig = {
  apiKey: 'AIzaSyA7B_Gx9teTVQIAA27euwvtV7SXELZNY3A',
  authDomain: 'zealous-rooms.firebaseapp.com',
  projectId: 'zealous-rooms',
  databaseURL: 'https://zealous-rooms-default-rtdb.firebaseio.com',
  storageBucket: 'zealous-rooms.appspot.com',
  messagingSenderId: '913370917613',
  appId: '1:913370917613:web:22c54fb8a9faa892d67ae4',
  measurementId: 'G-S5ELNN5Z71',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const rtdb = getDatabase(app)
const db = getFirestore(app)
const functions = getFunctions(app)
const storage = getStorage(app)
let analytics: Analytics

if (typeof window !== 'undefined') {
  analytics = getAnalytics()
}

const logEvent = (event: string, params?: Record<string, string>) => {
  if (analytics) {
    logAnalyticsEvent(analytics, event, params)
  }
}

if (process.env.NEXT_PUBLIC_FIREBASE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectDatabaseEmulator(rtdb, 'localhost', 9000)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectStorageEmulator(storage, 'localhost', 9199)
}

interface Firebase {
  auth: Auth
  db: Firestore
  rtdb: Database
  functions: Functions
  storage: FirebaseStorage
  logEvent: (event: string, params?: Record<string, string>) => void
}

export default function useFirebase(): Firebase {
  const [firebase] = useState<Firebase>({
    auth,
    db,
    functions,
    rtdb,
    storage,
    logEvent,
  })

  return firebase
}
