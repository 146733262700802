import { User as FirebaseUser } from 'firebase/auth'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import Account from 'src/models/Account'
import useFirebase from './useFirebase'

export interface User extends FirebaseUser {
  twitter?: Account
}

export default function useAuth(): [boolean, User | null] {
  const { auth, db } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(auth.currentUser)

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      const providerData =
        Array.isArray(user?.providerData) && user?.providerData[0]

      if (providerData && providerData.providerId === 'twitter.com') {
        const accountsRef = collection(db, 'accounts')
        const q = query(
          accountsRef,
          where('externalId', '==', providerData.uid)
        )
        const accounts = await getDocs(q)
        const account = accounts.docs[0]
        if (account) {
          // @ts-ignore
          user.twitter = account.data()
        }
      }

      setUser(user)
      setLoading(false)
    })
  }, [auth, db])

  return [loading, user ?? null]
}
