import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
  palette: {
    background: {
      default: '#F6F9FC',
    },
    primary: {
      main: '#F42574',
    },
    // secondary: {
    //   main: '#19857b',
    // },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F6F9FC',
          boxShadow: 'none',
        },
      },
    },
  },
})

export const twitterTheme = createTheme({
  palette: {
    primary: {
      main: '#1da1f2',
    },
    // secondary: {
    //   main: '#19857b',
    // },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: `0 0 5px 0px #1da1f2`,
        },
      },
    },
  },
})

export const twitterUserTheme = createTheme({
  palette: {
    primary: {
      main: '#1da1f2',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F6F9FC',
          boxShadow: 'none',
        },
      },
    },
  },
})

export default theme
